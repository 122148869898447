.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  margin: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 40px;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px;
}


/* Make sure these styles are included in your global stylesheet or component-specific stylesheet */

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  color: #167c5f;
}



#simple-popover {
  left: 25px !important;
  top: 5px !important;
}

@media screen and (max-width: 1200px) and (min-width: 956px) {
  #simple-popover {
    left: 45px !important;
  }
}

@media screen and (max-width: 956px) {
  #simple-popover {
    left: 7px !important;
  }
}



#menucart-popover{
  right: -20px;
}

@media screen and (max-width: 400px) {
  /* .MuiPopover-paper{ */
  .menucart-popover{
    max-width: 100% !important;
    left: 0 !important;
  }
 
}

@media screen and (max-width: 958px) {
  
  .image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height: 60vh !important;
  }
  
}

.image-gallery-bullets .image-gallery-bullet:focus ,.image-gallery-bullets .image-gallery-bullet:hover  {
  background-color: #167c5f !important;
  border: 1px solid #167c5f;

}
