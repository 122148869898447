body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imageGalleryClass {
  width: 100%;
  height: auto;
}
@import "~react-image-gallery/styles/css/image-gallery.css";